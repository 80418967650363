.error-page .error-content .heading {
    font-size: calc(1.925rem + 6.94286vw);
    font-weight: 700;
    line-height: calc(1.825rem + 5.91429vw);
    color: white;
    text-shadow: 2px 5px #FFA903;
  }

  .page-wrapper{
    position:relative;
    margin:0 auto;
    width:100%;
    min-width:300px;
    padding-top: 200px;
    padding-bottom: 100px;
  }
  
  @media (min-width: 1400px) {
    .error-page .error-content .heading {
      font-size: 8rem;
    }
  }
  
  @media (min-width: 1400px) {
    .error-page .error-content .heading {
      line-height: 7rem;
    }
  }