.testimonial-pagination{
    margin: 10px auto 0;
    text-align: center;
  }


  
  /* .swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-prev{
    width: 12px;
    height: 12px;
    background-color: var(--primary-color);
    opacity: 1;
  } */
  
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: var(--primary-color);
    opacity: 1;
    
  }
  .swiper-pagination-bullet-active{
    background: var(--second-color);
  }

  